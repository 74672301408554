import React, { useRef } from "react";
import { Link as RouterLink, graphql, PageProps } from "gatsby";
import { Box, Typography, Button, Link } from "@mui/material";
import Layout from "../../components/Layout";
import { MetaData } from "../../components/common/meta";
import bricks from "../../images/bricks.svg";
import Share from "../../components/Share";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Read from "../../images/research/read.inline.svg";
import fulldata from "../../../data/research.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType, Navigation, Autoplay } from "swiper";
import slugify from "react-slugify";

import Arrow2 from "../../images/media/arrow2.inline.svg";

function addElipsis(str: string) {
  const num = 45; //desired lenght
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

const navBtn = {
  width: { xs: 41 },
  height: { xs: 41 },
  border: { xs: "1px solid #4B608D", md: "none" },
  borderRadius: 0,
  transition: { xs: ".3s ease-in-out" },
  position: "absolute",
  top: { md: "50%" },
  bottom: { xs: 60, md: "initial" },
  transform: { md: "translateY(-50%)" },
  color: "#1CE7C2",
  "&:hover": {
    backgroundColor: { xs: "rgba(75, 96, 141, .5)", md: "initial" },
  },
  "& svg": {
    width: "21px",
    height: "auto",
  },
};

const article = ({ data, location }: { data: any; location: PageProps }) => {
  const article = data.allSitePage.edges[0].node.pageContext;
  const otherResearch = fulldata.filter((e) => e.name != article.name);
  const swiperRef = useRef<SwiperType>();

  return (
    <Layout>
      <MetaData title={article.name} location={location} type="website" />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: "-140px",
            top: "-360px",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
          }}
        >
          <Box
            component="div"
            sx={{
              mt: { xs: 12, md: 20 },
              mb: { xs: 6, md: 12 },
              textAlign: "center",
            }}
          >
            <Box component="div" sx={{ maxWidth: 880, mx: "auto" }}>
              <Typography sx={{ mb: 1.6, lineHeight: 1.2 }} variant="h3">
                {article.name}
              </Typography>
              <Typography
                sx={{
                  color: "#1CE7C2",
                  fontSize: 12,
                  mb: 6,
                  mt: 4,
                  "& span:not(:last-of-type):after": { content: "', '" },
                }}
              >
                {article.authors &&
                  article.authors.map((a, i) => (
                    <Box component="span" key={i}>
                      {a.url ? (
                        <Link
                          href={a.url}
                          target="_blank"
                          sx={{
                            textDecoration: "underline",
                            color: "#1CE7C2",
                          }}
                        >
                          {a.name}
                        </Link>
                      ) : (
                        <Box component="span">{a.name}</Box>
                      )}
                    </Box>
                  ))}
              </Typography>
            </Box>


            <Box
              component="div"
              sx={{
                mt: { xs: 3.5, md: 10 },
                display: "flex",
                textAlign: "left",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                component="div"
                sx={{
                  maxWidth: 600,
                  flex: { xs: "unset", md: "4" },
                  p: 1.5,
                  border: "1px solid #4B608D",
                  mx: "auto",
                }}
              >
                <Box
                  component="img"
                  src={article.cover}
                  sx={{ display: "block", width: "100%" }}
                />
              </Box>
              <Box
                component="div"
                sx={{
                  ml: { xs: 0, md: 11.4 },
                  flex: { xs: "unset", md: "5" },
                  mt: { xs: 3.5, md: 0 },
                }}
              >
                <Typography> {article.description}</Typography>

                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    mt: 3.5,
                  }}
                >
                  <Box component="div">
                    <Button
                      component={OutboundLink}
                      href={article.url}
                      target="_blank"
                      rel="noreferrer"
                      startIcon={<Read />}
                      sx={{
                        mb: 3,
                        ml: "4px",
                        "& svg": { fill: "none" },
                      }}
                    >
                      Read full article
                    </Button>
                    <Button
                      component={RouterLink}
                      to="/research"
                      target="_blank"
                      rel="noreferrer"
                      startIcon={<Read />}
                      sx={{
                        mb: 3,
                        ml: 4,
                        "& svg": {
                          fill: "none",
                          transformOrigin: "center",
                          transform: "rotate(180deg)",
                        },
                      }}
                    >
                      Back to Publications
                    </Button>
                  </Box>
                  <Share
                    url={`https://jumpcrypto.com/research/${slugify(
                      article.name
                    )}`}
                    title={article.name}
                  />
                </Box>
              </Box>
            </Box>


          </Box>
        </Box>

        <Box
          component="div"
          sx={{
            maxWidth: 1112,
            mx: "auto",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              display: "inline-block",
              background: "#091A3D",
              border: "1px solid #4B608D",
              px: { xs: 3, md: 6 },
              py: 3,
              position: "relative",
              mb: 5,
              "&:before": {
                content: '""',
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "#091A3D",
                border: "1px solid #4B608D",
                right: "-10px",
                top: "-10px",
                zIndex: -1,
              },
            }}
          >
            Other Publications
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            position: "relative",
            maxWidth: 1240,
            mx: "auto",
            pb: { xs: 12, md: 10 },
          }}
        >
          <Box component="div" sx={{ px: { md: 6.5 } }}>
            <Swiper
              modules={[Navigation, Autoplay]}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              loop={true}
              slidesPerView={3}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              preventClicks={false}
              preventClicksPropagation={false}
              autoplay={{
                delay: 4000,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },

                600: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
              }}
              className="media-slider"
            >
              {otherResearch.map((post, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    height: "auto",
                    display: "flex",
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={`/research/${slugify(post.name)}`}
                    sx={{
                      display: "block",
                      border: "1px solid #4B608D",
                      flex: 1,
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        p: 3.2,
                      }}
                    >
                      <Box
                        component="img"
                        src={post.cover}
                        sx={{ display: "block", width: "100%" }}
                      />
                      <Typography variant="h5" sx={{ mt: 3.2 }}>
                        {addElipsis(post.name)}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1CE7C2",
                          fontSize: 12,
                          mt: 1,
                          "& span:not(:last-of-type):after": {
                            content: "', '",
                          },
                        }}
                      >
                        {post.authors &&
                          post.authors.map((a, i) => (
                            <Box component="span" key={i}>
                              <Box component="span">{a.name}</Box>
                            </Box>
                          ))}
                      </Typography>
                    </Box>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Button
            sx={{
              ...navBtn,
              left: { xs: "initial", md: 0 },
              right: { xs: 60, md: 0 },
            }}
            className="prev"
          >
            <Arrow2 />
          </Button>
          <Button
            className="next"
            sx={{
              ...navBtn,
              right: 0,
              "& svg": {
                width: "21px",
                height: "auto",
                transformOrigin: "center",
                transform: "rotate(180deg)",
              },
            }}
          >
            <Arrow2 />
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default article;

export const postQuery = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          pageContext
        }
      }
    }
  }
`;
